@import url("https://www.onfarma.it/cdn/shop/t/38/assets/customer-fields.css");
@import url("https://static.customerfields.com/releases/4.14.2/customer-fields.css");

.cf-field-container {
  width: 50% !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

div[data-field-name="phone"],
div[data-field-type="checkbox"] {
  width: 100% !important;
}

div.cf-field-container label small {
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-size: 14px !important;
}

@media screen and (max-width: 768px) {
  div.cf-field-container {
    width: 100% !important;
  }
}

/* [Object] Modal
 * =============================== */
.widget-modal {
  opacity: 1;
  visibility: visible;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0, 0, 0, 0.6);
  transition: opacity 0.25s ease;
  z-index: 999;
}

.widget-modal__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.widget-modal-state {
  display: none;
}

.widget-modal-state:checked + .widget-modal {
  opacity: 1;
  visibility: visible;
}

.widget-modal-state:checked + .widget-modal .widget-modal__inner {
  top: 0;
}

.widget-modal__inner {
  transition: top 0.25s ease;
  position: absolute;
  top: -20%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 30%;
  margin: auto;
  overflow: auto;
  background: #fff;
  border-radius: 5px;
  padding: 1em 2em;
  max-height: 40vh;
}

.widget-modal__close {
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.1em;
  height: 1.1em;
  cursor: pointer;
}

.widget-modal__close:after,
.widget-modal__close:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 1.5em;
  background: #ccc;
  display: block;
  transform: rotate(45deg);
  left: 50%;
  margin: -3px 0 0 -1px;
  top: 0;
}

.widget-modal__close:hover:after,
.widget-modal__close:hover:before {
  background: #aaa;
}

.widget-modal__close:before {
  transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .widget-modal__inner {
    width: 90%;
    min-height: 50vh;
    box-sizing: border-box;
  }
}
