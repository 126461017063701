@import "https://www.onfarma.it/cdn/shop/t/38/assets/customer-fields.css";
@import "https://static.customerfields.com/releases/4.14.2/customer-fields.css";
.cf-field-container {
  width: 50% !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

div[data-field-name="phone"], div[data-field-type="checkbox"] {
  width: 100% !important;
}

div.cf-field-container label small {
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-size: 14px !important;
}

@media screen and (width <= 768px) {
  div.cf-field-container {
    width: 100% !important;
  }
}

.widget-modal {
  opacity: 1;
  visibility: visible;
  text-align: left;
  z-index: 999;
  background: #0009;
  transition: opacity .25s;
  position: fixed;
  inset: 0;
}

.widget-modal__bg {
  cursor: pointer;
  position: absolute;
  inset: 0;
}

.widget-modal-state {
  display: none;
}

.widget-modal-state:checked + .widget-modal {
  opacity: 1;
  visibility: visible;
}

.widget-modal-state:checked + .widget-modal .widget-modal__inner {
  top: 0;
}

.widget-modal__inner {
  background: #fff;
  border-radius: 5px;
  width: 30%;
  max-height: 40vh;
  margin: auto;
  padding: 1em 2em;
  transition: top .25s;
  position: absolute;
  inset: -20% 0 0;
  overflow: auto;
}

.widget-modal__close {
  cursor: pointer;
  width: 1.1em;
  height: 1.1em;
  position: absolute;
  top: 1em;
  right: 1em;
}

.widget-modal__close:after, .widget-modal__close:before {
  content: "";
  background: #ccc;
  width: 2px;
  height: 1.5em;
  margin: -3px 0 0 -1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: rotate(45deg);
}

.widget-modal__close:hover:after, .widget-modal__close:hover:before {
  background: #aaa;
}

.widget-modal__close:before {
  transform: rotate(-45deg);
}

@media screen and (width <= 768px) {
  .widget-modal__inner {
    box-sizing: border-box;
    width: 90%;
    min-height: 50vh;
  }
}
/*# sourceMappingURL=index.css.map */
